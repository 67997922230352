<template>
  <div style="padding-bottom: 150px">
  <el-form-item v-for="(item,i) in scoreWords" class="score-word">
    <el-input type="text" class="word" v-model="item.word" placeholder="关键词"></el-input>
    <el-input type="text" class="score" v-model="item.score" placeholder="分数"></el-input>
   </el-form-item>
    <el-form-item>
      <label name="btnAdd" class="el-form-item__label"  @click="addOption()" style="cursor: pointer; color: rgb(64, 175, 254);">增加得分点</label>
    </el-form-item>
  </div>
</template>
<script>
export default {
  data(){
    return{
      name: "QuestionOption",
      scoreWords:[],
    }
  },
  methods:{
    initData(scoreWords){
        for(let i=0;i<scoreWords.length;i++){
          if(scoreWords != null && i < scoreWords.length){
            this.scoreWords.push({score:scoreWords[i].score,word:scoreWords[i].word});
          }
          else{
            this.scoreWords.push({score:'',word:''});
          }
        }
    },
    getScoreWords(){
      let arr = [];
      for(let i=0;i<this.scoreWords.length;i++){
        if(this.scoreWords[i].word != ""){
          arr.push(this.scoreWords[i]);
        }
      }
      return arr;
    },
    addOption:function (){
      this.scoreWords.push({score:'',word:''});
    },
  }
}
</script>

<style scoped>
.score-word{
  display: inline-block;
  margin-right: 20px;
}
.word{
  float:left;
  width:200px;
  margin-bottom: 5px;
}
.score{
  float:left;
  width:60px;
  margin-bottom:5px;
  margin-left: 2px;
}
</style>
